body.meni {
    padding-top: 50px;
    @media (min-width: 768px) {
        padding-top: 60px;
    }
}
body.meni .main_content .choose_delivery {
    display: block !important;
    box-shadow: unset;
    
    @media (max-width: 575px) {
        position: relative;
        bottom: unset;
        box-shadow: unset;
        // height: 100px;
    }
}
body.meni .main_content {
    min-height: 60vh;
}
body.meni .menu_bar_container {
    display: none;
}
body.meni .location_cart_container {
    opacity: 0;
    pointer-events: none;
}
body.meni .mobile_location_cart_container {
    display: none !important;
}
body.meni .disclaimer {
    display: none;
}
