.qty_span {
    // padding: 3px;
    background-color: #fff;
    color: #333;
    border-radius: 50%;
    width: 20px;
    font-size: 14px;
    margin-left: 10px;
    font-weight: 600;
}
.mobile_qty_span {
    // padding: 3px;
    color: #fff;
    background-color: $red_1;
    border-radius: 50%;
    width: 20px;
    font-size: 14px;
    margin-left: 10px;
    font-weight: 600;
}