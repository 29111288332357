html, body {
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background: #ffffff none repeat scroll 0 0;
  color: #333;
  font-family: "Dosis", sans-serif;
  font-size: 16px;
}

h1, h2, h3, h4, h5, h6 {
  color: #333;
  font-family: "Dosis";
}

p {
  color: #333;
  line-height: 28px;
  margin-bottom: 25px;
}

a {
  color: #333;
  text-decoration: none;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  text-decoration: none;
  user-select: none;
}

a:hover {
  text-decoration: none;
  color: unset;
}

a:focus {
  outline: none;
}

img {
  max-width: 100%;
}

button {
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  border: none;
  outline: none !important;
}

.btn:focus,
.navbar-toggler:focus {
  box-shadow: none !important;
  outline: none !important;
}

ul, li, ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

fieldset {
  border: 0 none;
  margin: 0 auto;
  padding: 0;
}

label {
  color: #6c757d;
}

input:focus,
textarea:focus {
  outline: none;
  box-shadow: none;
}

iframe {
  border: 0;
  display: block;
}

video {
  width: 100%;
}

select:focus {
  outline: none;
  box-shadow: none;
}

div {
  box-sizing: border-box !important;
}

.heading {
  transform: scale(1.15);
  font-weight: 500;
  width: fit-content;
  letter-spacing: 0.1em;
}

.fit_content {
  width: fit-content;
}

/* scrollbar */
/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: #EDD229;
  background-color: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ED1C24;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #4BA345;
}

.scaleIn {
  animation: popIn 150ms ease-out;
}

@keyframes popIn {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
.fadeAway {
  animation: fadeOut 200ms ease-out;
}

@keyframes fadeOut {
  to {
    transform: scale(0.7);
    opacity: 0;
  }
}
.fadeIn {
  animation: fadeIn 1000ms ease-out;
}

.fadeInFast {
  animation: fadeIn 500ms ease-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.animateBorder {
  position: relative;
  z-index: 0;
  transition: 1s;
  border: 1px solid #ED1C24;
  box-sizing: unset !important;
  padding: 20px;
  border-radius: 100%;
  width: 200px;
  height: 200px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.animateBorder::before, .animateBorder::after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  z-index: -1;
  transition: 800ms ease-in-out;
  content: "";
}

.animateBorder::before {
  height: calc(100% + 5px);
  animation: collapseWidth 800ms ease-in-out;
}

.animateBorder::after {
  width: calc(100% + 5px);
  animation: collapseHeight 800ms ease-in-out;
}

@keyframes collapseWidth {
  0% {
    width: 80%;
  }
  100% {
    width: 0;
  }
}
@keyframes collapseHeight {
  0% {
    height: 80%;
  }
  100% {
    height: 0;
  }
}
.updated {
  animation: fadeColorInOut 2000ms ease-in;
}

@keyframes fadeColorInOut {
  0% {
    color: #4BA345;
  }
  75% {
    color: #4BA345;
  }
  100% {
    color: #333;
  }
}
.swinger {
  -webkit-animation-duration: 0.3s;
  -moz-animation-duration: 0.3s;
  -o-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  will-change: transform, opacity;
}
@media (max-width: 768px) {
  .swinger {
    animation-fill-mode: none;
    will-change: unset;
  }
}

.swinger.swing_in {
  -webkit-transform-origin: top;
  -moz-transform-origin: top;
  -ie-transform-origin: top;
  -o-transform-origin: top;
  transform-origin: top;
  -webkit-backface-visibility: visible !important;
  -webkit-animation-name: swingIn;
  -moz-backface-visibility: visible !important;
  -moz-animation-name: swingIn;
  -o-backface-visibility: visible !important;
  -o-animation-name: swingIn;
  backface-visibility: visible !important;
  animation-name: swingIn;
}
@media (max-width: 768px) {
  .swinger.swing_in {
    animation-name: none;
    transform-origin: unset;
  }
}

@keyframes swingIn {
  0% {
    transform: perspective(400px) rotateX(-90deg);
  }
  100% {
    transform: perspective(400px) rotateX(0deg);
  }
}
.swinger.swing_out {
  -webkit-transform-origin: top;
  -webkit-animation-name: swingOut;
  -webkit-backface-visibility: visible !important;
  -moz-animation-name: swingOut;
  -moz-backface-visibility: visible !important;
  -o-animation-name: swingOut;
  -o-backface-visibility: visible !important;
  transform-origin: top;
  animation-name: swingOut;
  backface-visibility: visible !important;
}
@media (max-width: 768px) {
  .swinger.swing_out {
    animation-name: none;
    transform-origin: unset;
  }
}

@keyframes swingOut {
  0% {
    transform: perspective(400px) rotateX(0deg);
  }
  100% {
    transform: perspective(400px) rotateX(-90deg);
  }
}
.qty_span {
  background-color: #fff;
  color: #333;
  border-radius: 50%;
  width: 20px;
  font-size: 14px;
  margin-left: 10px;
  font-weight: 600;
}

.mobile_qty_span {
  color: #fff;
  background-color: #ED1C24;
  border-radius: 50%;
  width: 20px;
  font-size: 14px;
  margin-left: 10px;
  font-weight: 600;
}

.jamaica * {
  z-index: 2;
}

.jamaica::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 65%;
  max-height: 133px;
  width: 30vw;
  background-image: url("../../img/brush-1.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left top;
  z-index: 1;
}

.jamaica::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  height: 65%;
  max-height: 133px;
  width: 30vw;
  background-image: url("../../img/brush-2.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right bottom;
  z-index: 1;
}

.heading::before {
  content: "";
  position: absolute;
  top: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
  width: 145px;
  max-width: 100%;
  height: 3px;
  background: linear-gradient(90deg, #4BA345 75%, transparent 75%);
}

.heading::after {
  content: "";
  position: absolute;
  top: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
  width: 145px;
  max-width: 100%;
  height: 3px;
  background: linear-gradient(90deg, transparent 78%, #ED1C24 78%);
}

.sub_heading {
  position: relative;
  width: fit-content;
  text-shadow: 0px 0px 2px #fff;
}

.sub_heading::before {
  content: "";
  position: absolute;
  top: 110%;
  left: 0;
  width: 145px;
  max-width: 100%;
  height: 3px;
  background: linear-gradient(90deg, #4BA345 75%, transparent 75%);
}

.sub_heading::after {
  content: "";
  position: absolute;
  top: 110%;
  left: 0;
  width: 145px;
  max-width: 100%;
  height: 3px;
  background: linear-gradient(90deg, transparent 78%, #ED1C24 78%);
}

/* underline element on hover */
.hover-underline {
  position: relative;
  width: fit-content;
}

.hover-underline::after {
  position: absolute;
  content: "";
  top: 100%;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #ED1C24;
  /* transition: width 150ms ease-out; */
  transition: transform 300ms ease-out;
  transform: scaleX(0);
  transform-origin: right;
  z-index: 4;
}

.hover-underline:hover::after {
  /* width: 100%; */
  transform: scaleX(1);
  transform-origin: left;
}

.single_product_price::before {
  content: "Jed. cena";
  position: absolute;
  bottom: 110%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 13px;
}
@media screen and (min-width: 768px) {
  .single_product_price::before {
    display: none;
  }
}

.total_product_price::before {
  content: "Ukupna cena";
  position: absolute;
  bottom: 110%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 13px;
}
@media screen and (min-width: 768px) {
  .total_product_price::before {
    display: none;
  }
}

/* popup */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 600;
  transition: opacity 150ms ease-in;
  pointer-events: none;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
}

.popup-div {
  position: relative;
  transform: scale(1.05);
  transition: transform 150ms ease-in;
  z-index: 21;
  box-sizing: border-box;
  min-width: 300px;
  min-height: 200px;
  max-width: 100vw;
  max-height: 100vh;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.show-popup {
  pointer-events: auto;
  opacity: 1;
}

.show-popup .popup-div {
  transform: scale(1);
}

.popup-close {
  position: fixed;
  z-index: 24;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50px;
  background-color: rgba(255, 255, 255, 0.8);
}

.popup-close-icon {
  transform: scale(2.3);
}

.popup-close-icon::before {
  vertical-align: middle !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (min-width: 768px) {
  .popup-div {
    width: 90vw;
    height: 85vh;
  }
}
@media screen and (min-width: 992px) {
  .popup-div {
    max-width: 980px;
  }
}
#loader {
  position: fixed;
  background: #fff;
  z-index: 99999999999;
  top: 0;
  left: 0;
  height: 100%;
  width: 100vw;
}

.spinner {
  width: 40px;
  height: 40px;
  top: 50%;
  left: 50%;
  margin-left: -40px;
  margin-top: -40px;
  -webkit-animation: rotatee 2s infinite linear;
  animation: rotatee 2s infinite linear;
}

.dot1, .dot2, .spinner {
  position: absolute;
}

.dot1, .dot2 {
  background-color: #cf0000;
}

.dot1, .dot2 {
  width: 60%;
  height: 60%;
  display: inline-block;
  top: 0;
  border-radius: 100%;
  -webkit-animation: bouncee 2s infinite ease-in-out;
  animation: bouncee 2s infinite ease-in-out;
}

.dot2 {
  top: auto;
  bottom: 0;
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes bouncee {
  0%, 100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}
@keyframes bouncee {
  0%, 100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
@-webkit-keyframes rotatee {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes rotatee {
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}
/* notification */
.custom_notification {
  background-color: #333;
  color: #fff;
  border: 1px solid rgba(146, 146, 146, 0.322);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  position: fixed;
  bottom: 16px;
  right: 16px;
  z-index: 1006;
  transition: all 500ms ease;
  transition-property: opacity, transform;
  margin-bottom: 10px;
  opacity: 0;
  transform: translateY(150%);
  pointer-events: none;
  max-width: 80%;
  min-width: 200px;
  cursor: pointer;
  box-sizing: border-box;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.35);
}

.show_notification {
  opacity: 1;
  transform: translateY(0);
  pointer-events: all;
}

.custom_notification i {
  margin-right: 5px;
}

@media (max-width: 600px) {
  .custom_notification {
    width: 100%;
    max-width: 100%;
    right: 0;
    border-radius: 0;
  }
}
.ripple_effect {
  position: relative;
  overflow: hidden !important;
}

span.ripple {
  position: absolute;
  border-radius: 50%;
  transform: scale(0);
  animation: ripple 600ms linear;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 4;
}

@keyframes ripple {
  to {
    transform: scale(3);
    opacity: 0;
  }
}
.roadmap {
  width: 90%;
  max-width: 800px;
}

.outline_icon {
  color: #fff !important;
  text-shadow: 0px 0px 2px #000;
}

.phase_completed {
  text-shadow: 0px 0px 2px #ED1C24;
}

.current_phase {
  font-size: 2.2rem;
  text-shadow: 0px 0px 3px #ED1C24 !important;
}

.roadmap_line {
  height: 1px;
  background-color: #afafaf;
}

.roadmap_line.phase_completed {
  background-color: #ED1C24;
}

header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 4 !important;
}

.custom-navbar {
  height: 50px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
  z-index: 4 !important;
}
@media (min-width: 768px) {
  .custom-navbar {
    height: 60px;
  }
}

.nav_list_container {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
}

.main_navigation * {
  width: 100%;
  box-sizing: border-box;
  color: #333 !important;
}

.main_navigation .nav-item {
  border-top: 1px solid #f3f3f3;
}

.navbar-brand {
  position: absolute;
  top: 0;
  transform: translateY(-10px);
  z-index: 3;
}

.navbar-toggler {
  border: none;
}

.meni_link {
  color: #ED1C24 !important;
  transition: all 200ms ease-in-out;
  transition-property: color, border-color;
  border: 1px solid transparent;
  border-radius: 4px;
}

.meni_link:hover {
  color: #ff0004 !important;
  border-color: #ff0004;
}

.navbar-lang {
  font-weight: 600;
  justify-self: flex-end;
}

.logo_link {
  height: 60px;
}

.logo_image {
  height: 60px;
  pointer-events: none;
  filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.25));
}
@media (min-width: 840px) {
  .logo_image {
    height: 100px;
  }
}

.cart_button {
  background-color: #ED1C24;
  color: #fff !important;
  display: flex;
  justify-content: space-evenly;
  justify-self: center;
  padding: 0.5rem 1rem;
  line-height: 20.22px !important;
  font-weight: bold;
  pointer-events: all;
  transition: background-color 150ms ease-out;
}

.cart_button:hover {
  background-color: #ff0004;
}

.cart_button i {
  margin: -3px 10px 0 0;
}

.mobile_location_cart_container {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 50px;
  background-color: #151414;
  color: #fff;
  z-index: 5;
  padding: 0 !important;
}

.mobile_location_button {
  display: flex;
  background-color: #151414;
  color: #fff;
  text-align: start !important;
  padding-left: 1rem;
}

.refresh_icon_container {
  color: #fff !important;
  padding: 0;
  padding-right: 10px;
}

.refresh_icon_container i::before,
.meni_link i::before {
  transform: translateY(-3px) !important;
}

.location,
.location_desc {
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
}

.location {
  text-transform: uppercase;
}

.location_desc {
  font-weight: 200;
  color: rgba(255, 255, 255, 0.884);
}
@media (min-width: 768px) {
  .location_desc {
    font-weight: 300;
  }
}

.mobile_cart_button {
  color: #fff !important;
  background-color: #151414;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  justify-self: center;
  padding: 0.5rem 1rem;
  line-height: 20.22px !important;
  font-weight: 400;
  opacity: 1;
  pointer-events: all;
  transition: opacity 150ms ease-out;
  letter-spacing: 0.08em;
  justify-self: flex-end !important;
}

.mobile_cart_button i {
  margin: -3px 10px 0 0;
  font-size: 1.1rem;
}

.location_cart_container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ED1C24;
  border-radius: 0.25rem;
}

.location_button {
  display: flex;
  background-color: #ED1C24;
  color: #fff;
  width: fit-content;
  border-radius: 0.25rem;
  transition: background-color 150ms ease-out;
  cursor: pointer;
  text-align: start !important;
  align-self: stretch;
}

.location_button:hover {
  background-color: #ff0004;
}

.menu_nav_container {
  background: #FAFAFA;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
  .menu_bar_container {
    padding: 0;
  }
}

.menu_nav {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.menu_nav_toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */
  letter-spacing: 0.1em;
  text-transform: uppercase;
  background-color: #ED1C24;
  color: #fff;
  border-radius: 0;
}

.menu_nav_toggle::after {
  content: "";
  width: 10px;
  height: 10px;
  border: 2px solid #fff;
  position: absolute;
  top: 12px;
  right: 40px;
  transform: rotate(45deg);
  border-color: transparent #fff #fff transparent;
}

@media (max-width: 768px) {
  .menu_nav_item {
    width: 100%;
    box-sizing: border-box;
  }
}

.menu_nav_link {
  display: flex;
  align-items: center;
  height: 40px;
  font-size: 0.8rem;
  font-weight: bold;
  line-height: 20px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  width: 100%;
  color: #333333;
  justify-content: center;
}
@media (min-width: 992px) {
  .menu_nav_link {
    height: 50px;
    font-size: 1rem;
  }
}

.menu_active {
  background: unset;
  color: #ED1C24;
  font-weight: 800 !important;
}
@media (min-width: 768px) {
  .menu_active {
    background: #EDD229;
    color: #333333;
  }
}

.choose_delivery {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
  position: relative;
  background-color: #fff;
  align-items: center;
  justify-content: stretch;
  width: 100%;
  flex-grow: 1 !important;
  padding: 0;
}
@media (max-width: 575px) {
  .choose_delivery {
    position: fixed;
    bottom: 50px;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.35);
  }
}

.choose_delivery_hidden {
  visibility: hidden;
  pointer-events: none;
}

.delivery-content {
  box-sizing: border-box;
  max-width: 100%;
  margin: 0;
}

.pokupi,
.dostava {
  height: 60px;
  color: #fff;
  transition: background-color 150ms ease-out;
}
@media (min-width: 576px) {
  .pokupi,
.dostava {
    border-radius: 4px;
  }
}

.pokupi {
  background: #006991;
}

.pokupi:hover {
  background: #0e7eaa;
}

.dostava {
  background: #ED1C24;
}

.dostava:hover {
  background: #ff0004;
}

.pokupi-icon,
.dostava-icon {
  border-radius: 4px;
  height: 60px;
}
@media (min-width: 576px) {
  .pokupi-icon,
.dostava-icon {
    height: 100%;
    background: #00000027;
  }
}

.dostava-text,
.pokupi-text {
  font-style: normal;
  font-weight: 400;
  color: #FFFFFF;
  font-size: 16px;
  white-space: nowrap;
}
@media (min-width: 576px) {
  .dostava-text,
.pokupi-text {
    font-size: 18px;
  }
}
@media (min-width: 768px) {
  .dostava-text,
.pokupi-text {
    font-size: 19px;
  }
}
@media (min-width: 992px) {
  .dostava-text,
.pokupi-text {
    font-size: 20px;
    letter-spacing: 0.03em;
  }
}
@media (min-width: 1200px) {
  .dostava-text,
.pokupi-text {
    font-size: 22px;
  }
}
@media (min-width: 1400px) {
  .dostava-text,
.pokupi-text {
    font-size: 24px;
  }
}

.location_layout {
  background-color: #fff;
  min-height: 100vh;
  min-width: 100vw;
}
@media screen and (min-width: 768px) {
  .location_layout {
    min-height: 300px;
    min-width: unset;
  }
}

.locations_header {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
  width: 100%;
  padding: 0.5rem 1rem;
  font-weight: bold;
  color: #ED1C24;
  font-size: 20px;
}

.locations_header_icon {
  width: 80px;
}

.locations_caption {
  font-size: 20px;
  padding: 0.5rem 1rem;
}

.city_picker {
  padding: 0.5rem 1rem;
  font-size: 20px;
  font-weight: 600;
  background-color: #ED1C24;
  color: #fff;
  text-transform: uppercase;
  border-radius: 4px;
  transition: background-color 150ms ease-in-out;
}

.city_picker:hover {
  background-color: #ff0004;
}

.red_glow {
  filter: drop-shadow(0px 0px 3px #ED1C24);
}

.blue_glow {
  filter: drop-shadow(0px 0px 3px #006991);
}

.pickup .locations_header {
  color: #006991;
}

.pickup .city_picker {
  background-color: #006991;
}

.pickup .city_picker:hover {
  background-color: #0e7eaa;
}

#map {
  display: none;
  width: 50vh;
  height: 50vh;
  background-color: #f4f4f4;
}
@media screen and (min-width: 768px) {
  #map {
    display: block;
  }
}

.restaurant_list_container {
  width: 100%;
  overflow: auto;
  scroll-behavior: smooth;
}
@media screen and (min-width: 768px) {
  .restaurant_list_container {
    height: 50vh;
    width: 250px;
  }
}

.restaurant_card {
  border-bottom: 1px solid #ececec;
  cursor: pointer;
  transition: box-shadow 150ms ease-in-out;
}

.restaurant_card.active {
  box-shadow: inset 0px 0px 20px rgba(0, 0, 0, 0.3);
}

.restaurant_button,
.marker_button {
  width: 100%;
  max-width: 400px;
  background-color: #006991;
  color: #fff;
  padding: 0.3rem;
  text-transform: uppercase;
  font-weight: 400;
  border-radius: 4px;
  transition: background-color 150ms ease-in-out;
}

.marker_button {
  color: #ED1C24;
  background-color: #fff;
  font-size: 16px;
  border: 1px solid #fff;
  transition: all 150ms ease-in-out;
  transition-property: color, background-color;
}

.marker_button:hover {
  background-color: #ED1C24;
  color: #fff;
}

.restaurant_button:hover {
  background-color: #0e7eaa;
}

.restaurant_icon::before {
  transform: translateY(-3px) !important;
}

.restaurant_map_link {
  border: 1px solid transparent;
  transition: border-color 150ms ease-in-out;
  border-radius: 4px;
}

.restaurant_map_link:hover {
  border-color: #006991;
}

.restaurant_map_link i::before {
  transform: translateY(-3px);
}

.back_button {
  background-color: #fff;
  color: #ED1C24;
  border: 1px solid transparent;
  transition: all 150ms ease-in-out;
  transition-property: box-shadow;
  border-radius: 4px;
}

.back_button:hover {
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
}

.pickup .back_button {
  color: #006991;
}

.marker_popup_card {
  font-family: "Dosis", sans-serif;
}

.marker_popup_title {
  font-weight: 700;
  font-size: 18px;
  color: #ED1C24;
}

.marker_popup_image {
  width: 100% !important;
  height: 110px;
  object-fit: cover;
}

.marker_popup_content {
  background-color: #ED1C24;
  color: #fff;
}

.marker_popup_phone {
  font-weight: 500;
  font-size: 16px;
}

.gm-style-iw.gm-style-iw-c,
.gm-style-iw-d {
  max-height: unset !important;
}

/* width */
.restaurant_list_container::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.restaurant_list_container::-webkit-scrollbar-track {
  background-color: #fff;
}

/* Handle */
.restaurant_list_container::-webkit-scrollbar-thumb {
  background: #006991;
}

/* Handle on hover */
.restaurant_list_container::-webkit-scrollbar-thumb:hover {
  background: #0e7eaa;
}

.action_image {
  box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.2);
}

.news_item {
  box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.2);
}

.disclaimer {
  background: #FAFAFA;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  color: #777;
  font-weight: 500;
}

.footer_banner {
  background-color: #ED1C24;
}

.banner_holder {
  justify-content: center;
}
@media (min-width: 992px) {
  .banner_holder {
    justify-content: unset;
  }
}

.banner_icon {
  filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.2));
}

.banner_title {
  font-weight: 500;
  font-size: 35px;
  color: #FFFFFF;
  text-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
}
@media (min-width: 576px) {
  .banner_title {
    font-size: 50px;
  }
}
@media (min-width: 1200px) {
  .banner_title {
    font-size: 4vw;
    line-height: 80px !important;
  }
}

.banner_message {
  font-weight: 500;
  width: fit-content;
  position: relative;
  padding: 40px 20px;
  text-align: center;
  font-size: 36px;
  color: #FFFFFF;
  text-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
}
@media (min-width: 1200px) {
  .banner_message {
    font-size: 2.7vw;
    line-height: 40px !important;
  }
}

.banner_message span {
  font-size: 24px;
  text-transform: uppercase;
  color: #333333;
  text-shadow: none;
}

.banner_message::before,
.banner_message::after {
  content: "";
  position: absolute;
  top: 20px;
  width: 100%;
  height: 5px;
  background-color: #fff;
  border-radius: 5px;
}

.banner_message::after {
  position: absolute;
  bottom: 20px;
  top: unset;
}

.footer_content {
  background-color: #151414;
  position: relative;
}

.footer_item_title {
  position: relative;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.15em;
  color: #FFFFFF;
  margin-bottom: 20px;
}

.footer_item_title::before {
  content: "";
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  width: 50px;
  height: 3px;
  background-color: #ED1C24;
}

.footer_content {
  border-top: solid 12px #BF181E;
}

.footer_item {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  width: fit-content;
}

.footer_item ul {
  list-style-type: none;
  margin-bottom: 20px;
}

.footer_item li a {
  padding: 3px 0;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 28px;
  color: rgba(255, 255, 255, 0.8);
}

.footer_item li a span {
  color: #fff;
  font-weight: 700;
}

.franchise_button {
  width: 244px;
  padding: 12px 30px;
  background-color: #EDD229;
  font-weight: 800;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.15em;
  color: #333333;
  text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.15);
  text-align: start;
  position: relative;
  border-radius: 5px;
}

.franchise_button:hover {
  background-color: #ffeb66;
}

.franchise_button::after {
  content: "NOVO";
  position: absolute;
  top: 0;
  right: 10px;
  transform: translateY(-50%);
  background-color: #fff;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  padding: 3px 10px;
  border-radius: 5px;
  letter-spacing: 0px;
}

.footer_social {
  width: fit-content;
  color: #EDD229;
}

.footer_social:hover {
  color: #ffeb66;
}

.footer_copyright {
  font-weight: normal;
  font-size: 13px;
  line-height: 28px;
  color: rgba(255, 255, 255, 0.4);
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.footer_item .logo_image {
  height: auto !important;
}

.pizza_sizes {
  position: relative;
  background: #FAFAFA;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
}
@media (min-width: 1200px) {
  .pizza_sizes {
    padding-top: 20px;
  }
}

.sizes,
.types {
  transform: scale(0.8);
}
@media (min-width: 1200px) {
  .sizes,
.types {
    transform: scale(1);
  }
}

.size_heading,
.type_heading {
  margin: 0 auto;
}

.size_item,
.type_item {
  height: auto;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
}

.size_item {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
}

.size_title {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #333333;
  background-color: #EDD229;
  padding: 0.2rem 1.2rem;
  white-space: nowrap;
  width: 100%;
}

.size_image {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  background-color: #222222;
  height: 100%;
  width: 100%;
}

.size_description {
  border: 1px solid #EDD229;
  box-sizing: border-box;
  width: 100%;
}

.size_key {
  background-color: #EDD229;
  height: 100%;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}

.size_value {
  white-space: nowrap;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #333333;
}

.types {
  display: flex;
  flex-flow: column;
  justify-content: space-around;
}

.type_image {
  width: 80px;
  border: 4px solid #333333;
  box-sizing: border-box;
  border-radius: 100px;
  overflow: hidden;
  margin-bottom: 0.5rem;
}

.type_image > img {
  transform: scale(1.1);
}

.type_description {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #333333;
  background-color: #EDD229;
  padding: 0.2rem;
  border-radius: 4px;
  white-space: nowrap;
  min-width: 100%;
}

.product {
  background: #FFFFFF;
  border-radius: 3px;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
}
@media (min-width: 768px) {
  .product {
    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.2);
  }
}

.product_name {
  font-weight: bold;
  font-size: 1rem;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0;
  text-transform: uppercase;
  color: #333333;
}
@media (min-width: 768px) {
  .product_name {
    font-size: 1.1rem;
  }
}
@media (min-width: 1400) {
  .product_name {
    letter-spacing: 0.1em;
  }
}

.product_select {
  background: #F4F4F4;
  border: 1px solid #AEAEAE;
  box-sizing: border-box;
  border-radius: 3px;
  letter-spacing: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #000000;
}
@media (min-width: 576px) {
  .product_select {
    font-size: 14px;
    letter-spacing: 0.05em;
  }
}
@media (min-width: 768px) {
  .product_select {
    font-size: 15px;
    letter-spacing: 0.07em;
  }
}

.extra_ings {
  background: #F2F2F2;
  border: 1px solid #BDBDBD;
  box-sizing: border-box;
  border-radius: 3px;
  font-style: normal;
  font-weight: normal;
  line-height: 20px;
  text-align: center;
  font-size: 13px;
  letter-spacing: 0em;
  color: #000000;
}
@media (min-width: 576px) {
  .extra_ings {
    font-size: 15px;
  }
}
@media (min-width: 768px) {
  .extra_ings {
    font-size: 16px;
  }
}

.add_to_cart {
  background: #ED1C24;
  border: 1px solid #BF181E;
  box-sizing: border-box;
  border-radius: 3px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  color: #FFFFFF;
}
@media (min-width: 768px) {
  .add_to_cart {
    font-size: 17px;
    letter-spacing: 0.1em;
    font-weight: 600;
  }
}

.product_with_ings {
  background: #FFFFFF;
  border-radius: 3px;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
}
@media (min-width: 768px) {
  .product_with_ings {
    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.2);
  }
}

.product_with_ings_name {
  font-weight: bold;
  font-size: 1rem;
  text-align: center;
  letter-spacing: 0;
  text-transform: uppercase;
  color: #333333;
}
@media (min-width: 576px) {
  .product_with_ings_name {
    font-size: 1.5rem;
  }
}
@media (min-width: 768px) {
  .product_with_ings_name {
    font-size: 2.5vw;
    font-weight: 600;
  }
}
@media (min-width: 1400px) {
  .product_with_ings_name {
    letter-spacing: 0.05em;
    font-size: 2vw;
    font-weight: 500;
  }
}

.default_ingredient {
  border: 1px solid #ED1C24;
  border-radius: 3px;
  font-size: 14px;
  color: #ED1C24;
}
@media (min-width: 576px) {
  .default_ingredient {
    font-size: 16px;
  }
}

.product_with_ings_select {
  background: #F4F4F4;
  border: 1px solid #AEAEAE;
  box-sizing: border-box;
  border-radius: 3px;
  letter-spacing: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #000000;
}
@media (min-width: 576px) {
  .product_with_ings_select {
    font-size: 14px;
    letter-spacing: 0.05em;
  }
}
@media (min-width: 768px) {
  .product_with_ings_select {
    font-size: 15px;
    letter-spacing: 0.07em;
  }
}

.qty {
  background-color: #F2F2F2;
  color: #000;
  border-radius: 3px;
  height: 100%;
  border: 1px solid #BDBDBD;
}

.qty i {
  font-weight: 100 !important;
}

.qty i::before {
  vertical-align: middle !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 22px;
  font-weight: 100 !important;
}
@media (min-width: 768px) {
  .qty i::before {
    font-size: 24px;
  }
}

.qty_inc,
.qty_dec,
.qty_value {
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  position: relative;
  user-select: none;
  font-weight: 600;
}

.qty_inc,
.qty_dec {
  font-size: 20px;
  cursor: pointer;
  position: relative;
  color: #222222;
}

.qty_value {
  border-left: 1px solid #BDBDBD;
  border-right: 1px solid #BDBDBD;
}

.ing {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
  position: relative;
  cursor: pointer;
}

.ingQty {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #ED1C24;
  color: #fff;
  border-radius: 3px 3px 0px 0px;
  transition: all 150ms ease-out;
  transition-property: opacity, transform;
  opacity: 0;
  pointer-events: none;
}

.show_ing_qty {
  opacity: 1;
  pointer-events: all;
}

.toggle_ing {
  padding-top: 30px;
  user-select: none;
}

.toggle_ing > img {
  user-select: none;
}

.ingQty i {
  font-weight: 100 !important;
}

.ingQty i::before {
  vertical-align: middle !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 22px;
  font-weight: 100 !important;
}
@media (min-width: 768px) {
  .ingQty i::before {
    font-size: 24px;
  }
}

.ingQty_inc,
.ingQty_dec,
.ingQty_value {
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  position: relative;
  user-select: none;
}

.ingQty_inc,
.ingQty_dec {
  font-size: 20px;
  cursor: pointer;
  position: relative;
}

.ingQty_value {
  border-left: 1px solid #FD5057;
  border-right: 1px solid #FD5057;
  cursor: auto;
}

.ingName,
.ingPrice {
  user-select: none;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #333333;
}
@media (min-width: 768px) {
  .ingName,
.ingPrice {
    font-size: 16px;
    line-height: 19px;
  }
}

.cart_layout {
  background-color: #fff;
  min-height: 100vh;
  min-width: 100vw;
}
@media screen and (min-width: 768px) {
  .cart_layout {
    min-height: 100%;
    min-width: unset;
  }
}

.cart_list_header {
  border-right: 1px solid #ED1C24;
  background-color: #333;
}

.cart_list_header > div {
  white-space: nowrap;
  border: 1px solid #ED1C24;
  border-right: none;
  padding: 0.5rem;
  color: #ED1C24;
  color: #fff;
  font-weight: 500;
}

.order_button {
  background: #ED1C24;
  border: 1px solid #BF181E;
  box-sizing: border-box;
  border-radius: 3px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0em;
  text-transform: uppercase;
  color: #FFFFFF;
  width: 100%;
}
@media (min-width: 576px) {
  .order_button {
    font-size: 17px;
    letter-spacing: 0.1em;
    width: 300px;
  }
}

.order_icon::before {
  transform: rotate(90deg) translateX(-3px);
  font-weight: bold;
}

.empty_cart_text {
  font-size: 1.3rem;
  font-weight: 600;
  color: #858585;
}

.cart_item {
  border-bottom: 1px solid #ececec;
  padding-bottom: 1rem;
}

.cart_item:last-child {
  border-bottom: 1px solid #ED1C24;
}

.cart_item_name {
  font-weight: 600;
}

.cart_item_type {
  color: black;
  text-shadow: 2px 4px 4px rgba(0, 0, 0, 0.2);
  font-weight: 500;
}

.qty_control {
  height: fit-content;
  max-width: 160px;
}

.remove_from_cart {
  cursor: pointer;
  transition: color 150ms ease-out;
  padding: 0 15px;
}

.remove_from_cart:hover {
  color: #ED1C24;
}

.single_product_price,
.total_product_price {
  position: relative;
}

.cart_ingQty_edit,
.cart_ingQty_save {
  color: #333;
  cursor: pointer;
  transition: color 150ms ease-in-out;
}

.cart_ingQty_edit:hover,
.cart_ingQty_save:hover {
  color: #4BA345;
}

.cart_ingQty {
  color: #4BA345;
  background-color: #fff;
  border-radius: 3px 3px 0px 0px;
  transition: all 150ms ease-out;
  transition-property: opacity, transform;
  border: 1px solid #4BA345;
}

.cart_ingQty i {
  font-weight: 100 !important;
}

.cart_ingQty i::before {
  vertical-align: middle !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 22px;
  font-weight: 100 !important;
}
@media (min-width: 768px) {
  .cart_ingQty i::before {
    font-size: 24px;
  }
}

.cart_ingQty_inc,
.cart_ingQty_dec,
.cart_ingQty_value {
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  position: relative;
  user-select: none;
}

.cart_ingQty_inc,
.cart_ingQty_dec {
  font-size: 20px;
  cursor: pointer;
  position: relative;
}

.cart_ingQty_value {
  border-left: 1px solid #4BA345;
  border-right: 1px solid #4BA345;
  cursor: auto;
}

body.home {
  padding-top: 50px;
  padding-bottom: 110px;
}
@media (min-width: 576px) {
  body.home {
    padding-top: 134px;
    padding-bottom: 50px;
  }
}
@media (min-width: 768px) {
  body.home {
    padding-top: 144px;
    padding-bottom: unset;
  }
}

body.home .menu_bar_container {
  display: none;
}

body.home .choose_delivery {
  display: block !important;
}

body.pizze {
  padding-top: 50px;
  padding-bottom: 50px;
}
@media (min-width: 768px) {
  body.pizze {
    padding-top: 110px;
    padding-bottom: unset;
  }
}

body.akcije {
  padding-top: 50px;
  padding-bottom: 50px;
}
@media (min-width: 768px) {
  body.akcije {
    padding-top: 110px;
    padding-bottom: unset;
  }
}

body.akcije .product_list {
  justify-content: center;
}

.action_product {
  margin: 10px;
}
@media (min-width: 768px) {
  .action_product {
    max-width: 420px;
  }
}

body.meni {
  padding-top: 50px;
}
@media (min-width: 768px) {
  body.meni {
    padding-top: 60px;
  }
}

body.meni .main_content .choose_delivery {
  display: block !important;
  box-shadow: unset;
}
@media (max-width: 575px) {
  body.meni .main_content .choose_delivery {
    position: relative;
    bottom: unset;
    box-shadow: unset;
  }
}

body.meni .main_content {
  min-height: 60vh;
}

body.meni .menu_bar_container {
  display: none;
}

body.meni .location_cart_container {
  opacity: 0;
  pointer-events: none;
}

body.meni .mobile_location_cart_container {
  display: none !important;
}

body.meni .disclaimer {
  display: none;
}

body.pola {
  padding-top: 50px;
  padding-bottom: 50px;
}
@media (min-width: 768px) {
  body.pola {
    padding-top: 110px;
    padding-bottom: unset;
  }
}

body.burgeri {
  padding-top: 50px;
  padding-bottom: 50px;
}
@media (min-width: 768px) {
  body.burgeri {
    padding-top: 110px;
    padding-bottom: unset;
  }
}

body.sendvici {
  padding-top: 50px;
  padding-bottom: 50px;
}
@media (min-width: 768px) {
  body.sendvici {
    padding-top: 110px;
    padding-bottom: unset;
  }
}