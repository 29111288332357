.roadmap {
    width: 90%;
    max-width: 800px;
}
// icons
.outline_icon {
    color: #fff !important;
    text-shadow: 0px 0px 2px #000;
}
.phase_completed {
    text-shadow: 0px 0px 2px $red_1;
}
.current_phase {
    font-size: 2.2rem;
    text-shadow: 0px 0px 3px $red_1 !important;
}

// lines
.roadmap_line {
    height: 1px;
    background-color: rgb(175, 175, 175);
}

.roadmap_line.phase_completed {
    background-color: $red_1;
}
