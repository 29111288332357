.product {
    background: #FFFFFF;
    border-radius: 3px;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
    @media (min-width: 768px) {
        box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.2);
    }
}
.product_name {
    font-weight: bold;
    font-size: 1rem;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0;
    text-transform: uppercase;
    color: #333333;
    @media (min-width: 768px) {
        font-size: 1.1rem;
    }
    @media (min-width: 1400) {
        letter-spacing: 0.1em;
    }
}
.product_select {
    background: $grey_2;
    border: 1px solid #AEAEAE;
    box-sizing: border-box;
    border-radius: 3px;
    letter-spacing: 0;

    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: #000000;

    @media (min-width: 576px) {
        font-size: 14px;
        letter-spacing: 0.05em;
    }
    @media (min-width: 768px) {
        font-size: 15px;
        letter-spacing: 0.07em;
    }
}
.extra_ings {
    background: $grey_1;
    border: 1px solid #BDBDBD;
    box-sizing: border-box;
    border-radius: 3px;
    // padding: 8px 10px;
    font-style: normal;
    font-weight: normal;
    line-height: 20px;
    text-align: center;
    font-size: 13px;
    letter-spacing: 0em;

    color: #000000;
    @media (min-width: 576px) {
        font-size: 15px;
        // letter-spacing: 0.1em;
    }
    
    @media (min-width: 768px) {
        font-size: 16px;
        // letter-spacing: 0.1em;
    }
}
.add_to_cart {
    background: $red_1;
    border: 1px solid #BF181E;
    box-sizing: border-box;
    border-radius: 3px;
    
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;

    text-align: center;
    letter-spacing: 0.07em;
    text-transform: uppercase;

    color: #FFFFFF;

    @media (min-width: 768px) {
        font-size: 17px;
        letter-spacing: 0.1em;
        font-weight: 600;
    }
}