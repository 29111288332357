.disclaimer {
    background: #FAFAFA;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
    color: #777;
    font-weight: 500;
}
.footer_banner {
    background-color: $red_1;
}
.banner_holder {
    justify-content: center;
    @media (min-width: 992px) {
        justify-content: unset;
    }
}
.banner_icon {
    filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.2))
}
.banner_title {
    font-weight: 500;
    font-size: 35px;
    color: #FFFFFF;
    text-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
    // line-height: 60px;
    @media (min-width: 576px) {
        font-size: 50px;
    }
    @media (min-width: 1200px) {
        font-size: 4vw;
        line-height: 80px !important;
    }
}
.banner_message {
    font-weight: 500;
    width: fit-content;
    position: relative;
    padding: 40px 20px;
    text-align: center;

    font-size: 36px;
    color: #FFFFFF;
    text-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
    @media (min-width: 1200px) {
        font-size: 2.7vw;
        line-height: 40px !important;
    }
}
.banner_message span {
    font-size: 24px;
    text-transform: uppercase;
    color: #333333;
    text-shadow: none;
}
.banner_message::before,
.banner_message::after {
    content: '';
    position: absolute;
    top: 20px;
    width: 100%;
    height: 5px;
    background-color: #fff;
    border-radius: 5px;
}
.banner_message::after {
    position: absolute;
    bottom: 20px;
    top: unset;
}

// footer content
.footer_content {
    background-color: #151414;
    position: relative;
}
.footer_item_title{
    position: relative;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.15em;
    color: #FFFFFF;
    margin-bottom: 20px;
}
.footer_item_title::before {
    content: '';
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    width: 50px;
    height: 3px;
    background-color: $red_1;
}
.footer_content {
    border-top: solid 12px #BF181E;
}
.footer_item {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    width: fit-content;
}
.footer_item ul{
    list-style-type: none;
    margin-bottom: 20px;
}
.footer_item li a{
    padding: 3px 0;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 28px;
    color: rgba(255, 255, 255, 0.8);
}
.footer_item li a span{
    color:#fff;
    font-weight: 700;
}
.franchise_button {
    width: 244px;
    padding: 12px 30px;
    background-color: $yellow_1;
    
    font-weight: 800;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.15em;

    color: #333333;

    text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.15);
    text-align: start;
    position: relative;
    border-radius: 5px;
}
.franchise_button:hover {
    background-color: $yellow_1_hover;
}
.franchise_button::after {
    content: 'NOVO';
    position: absolute;
    top: 0;
    right: 10px;
    transform: translateY(-50%);
    background-color: #fff;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    padding: 3px 10px;
    border-radius: 5px;
    letter-spacing: 0px;
}
.footer_social {
    width: fit-content;
    color: $yellow_1;
}
.footer_social:hover {
    color: $yellow_1_hover;
}
.footer_copyright {
    font-weight: normal;
    font-size: 13px;
    line-height: 28px;
    color: rgba(255, 255, 255, 0.4);
    border-top: 1px solid rgba(255, 255, 255, 0.2);
}
.footer_item .logo_image {
    height: auto !important;
}