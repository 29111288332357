body.akcije {
    padding-top: 50px;
    padding-bottom: 50px;
    
    @media (min-width: 768px) {
        padding-top: 110px;
        padding-bottom: unset;
    }
}
body.akcije .product_list {
    justify-content: center;
}
.action_product {
    margin: 10px;
    @media (min-width: 768px) {
        max-width: 420px;
    }
}
// .action_item:first-child {
// }