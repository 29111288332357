header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 4 !important;
}
.custom-navbar {
    height: 50px;
    box-shadow: $box_shadow_1;
    z-index: 4 !important;
    @media (min-width: 768px) {
        height: 60px;
    }
}
.nav_list_container {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    box-shadow: $box_shadow_1;
}
.main_navigation * {
    width: 100%;
    box-sizing: border-box;
    color: #333 !important;
}
.main_navigation .nav-item {
    border-top: 1px solid rgb(243, 243, 243);
}
.navbar-brand {
    position: absolute;
    top: 0;
    transform: translateY(-10px);
    z-index: 3;
}
.navbar-toggler{
    border: none;
}
.meni_link {
    color: $red_1 !important;
    transition: all 200ms ease-in-out;
    transition-property: color, border-color;
    border: 1px solid transparent;
    border-radius: 4px;
}
.meni_link:hover {
    color: $red_1_hover !important;
    border-color: $red_1_hover;
}
.navbar-lang {
    font-weight: 600;
    justify-self: flex-end;
}
.logo_link {
    height: 60px;
}
.logo_image {
    // width: 86px;
    height: 60px;
    pointer-events: none;
    filter:drop-shadow($box_shadow_3);
    @media (min-width: 840px) {
        // width: 160px;
        height: 100px;
    }
}
.cart_button {
    background-color: $red_1;
    color: #fff !important;
    display: flex;
    justify-content: space-evenly;
    justify-self: center;
    padding: 0.5rem 1rem;
    line-height: 20.22px !important;
    font-weight: bold;
    pointer-events: all;
    transition: background-color 150ms ease-out;
}
.cart_button:hover {
    background-color: $red_1_hover;
}
.cart_button i {
    margin: -3px 10px 0 0;
}

// mobile fixed location and cart
.mobile_location_cart_container {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 50px;
    background-color: #151414;
    color: #fff;
    z-index: 5;
    padding: 0 !important;
}
.mobile_location_button {
    display: flex;
    background-color: #151414;
    color: #fff;
    text-align: start !important;
    padding-left: 1rem;
    // flex-grow: 1;
}
.refresh_icon_container {
    color: #fff !important;
    // font-size: 1.3rem;
    padding: 0;
    padding-right: 10px;
}
.refresh_icon_container i::before ,
.meni_link i::before {
    transform: translateY(-3px) !important;
}
.location,
.location_desc {
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
}
.location {
    text-transform: uppercase;
}
.location_desc {
    font-weight: 200;
    color: rgba(255, 255, 255, 0.884);
    @media (min-width: 768px) {
        font-weight: 300;
    }
}
.mobile_cart_button {
    color: #fff !important;
    background-color: #151414;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    justify-self: center;
    padding: 0.5rem 1rem;
    line-height: 20.22px !important;
    font-weight: 400;
    opacity: 1;
    pointer-events: all;
    transition: opacity 150ms ease-out;
    // padding-right: 0;
    letter-spacing: 0.08em;
    // min-width: 140px;
    // flex-grow: 1;
    justify-self: flex-end !important;
}
.mobile_cart_button i {
    margin: -3px 10px 0 0;
    font-size: 1.1rem;
}
.location_cart_container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $red_1;
    border-radius: 0.25rem;
}

.location_button {
    display: flex;
    background-color: $red_1;
    color: #fff;
    width: fit-content;
    border-radius: 0.25rem;
    transition: background-color 150ms ease-out;
    cursor: pointer;
    text-align: start !important;
    align-self: stretch;
}
.location_button:hover {
    background-color: $red_1_hover;
}