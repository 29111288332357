.scaleIn {
    animation: popIn 150ms ease-out;
}
@keyframes popIn {
    0%{transform:scale(1)}
    50%{transform:scale(1.05)}
    100%{transform:scale(1)} 
}
// .growUp {
//     animation: grow 150ms ease-out;
// }
// @keyframes grow {
//     0%{transform:scale(0.6)}
//     100%{transform:scale(1)} 
// }
.fadeAway {
    animation: fadeOut 200ms ease-out;
}
@keyframes fadeOut {
    to {
        transform:scale(0.7);
        opacity: 0;
    } 
}

.fadeIn {
    animation: fadeIn 1000ms ease-out;
}

.fadeInFast {
    animation: fadeIn 500ms ease-out;
}
@keyframes fadeIn {
    0%{opacity: 0;}
    100%{opacity: 1;} 
}

// animate border in circle
.animateBorder {
    position: relative;
    z-index: 0;
    transition: 1s;
    border: 1px solid $red_1;
    box-sizing: unset !important;
    padding: 20px;
    border-radius: 100%;
    width: 200px;
    height: 200px;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
}
.animateBorder::before, .animateBorder::after {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    z-index: -1;
    transition:800ms ease-in-out;
    content: '';
}

.animateBorder::before {
    height: calc(100% + 5px);
    animation: collapseWidth 800ms ease-in-out;
}

.animateBorder::after {
    width: calc(100% + 5px);
    animation: collapseHeight 800ms ease-in-out;
}

@keyframes collapseWidth {
    0%{width: 80%;}
    100%{width: 0;} 
}
@keyframes collapseHeight {
    0%{height: 80%;}
    100%{height: 0;} 
}

// highlight updated element

.updated {
    animation: fadeColorInOut 2000ms ease-in;
}
@keyframes fadeColorInOut {
    0% {color:$green_1;}
    75% {color:$green_1;}
    100% {color: #333;}
}

// skew header on scroll
.swinger {
    -webkit-animation-duration: .3s;
    -moz-animation-duration: .3s;
    -o-animation-duration: .3s;
    animation-duration: .3s;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both;
    will-change: transform, opacity;

    @media (max-width: 768px) {
        animation-fill-mode: none;
        will-change: unset;
    }
}
.swinger.swing_in {
    -webkit-transform-origin: top;
    -moz-transform-origin: top;
    -ie-transform-origin: top;
    -o-transform-origin: top;
    transform-origin: top;
    -webkit-backface-visibility: visible !important;
    -webkit-animation-name: swingIn;
    -moz-backface-visibility: visible !important;
    -moz-animation-name: swingIn;
    -o-backface-visibility: visible !important;
    -o-animation-name: swingIn;
    backface-visibility: visible !important;
    animation-name: swingIn;

    @media (max-width: 768px) {
        animation-name: none;
        transform-origin: unset;
    }
}

@keyframes swingIn {
    0% {
        transform: perspective(400px) rotateX(-90deg)
    }
    100% {
        transform: perspective(400px) rotateX(0deg)
    }
}
.swinger.swing_out {
    -webkit-transform-origin: top;
    -webkit-animation-name: swingOut;
    -webkit-backface-visibility: visible !important;
    -moz-animation-name: swingOut;
    -moz-backface-visibility: visible !important;
    -o-animation-name: swingOut;
    -o-backface-visibility: visible !important;
    transform-origin: top;
    animation-name: swingOut;
    backface-visibility: visible !important;

    @media (max-width: 768px) {
        animation-name: none;
        transform-origin: unset;
    }
}

@keyframes swingOut {
    0% {
        transform: perspective(400px) rotateX(0deg)
    }
    100% {
        transform: perspective(400px) rotateX(-90deg)
    }
}