.cart_item {
    border-bottom: 1px solid rgb(236, 236, 236);
    padding-bottom: 1rem;
}
.cart_item:last-child {
    border-bottom: 1px solid $red_1;
}
.cart_item_name {
    font-weight: 600;
}
.cart_item_type {
    color: rgb(0, 0, 0);
    text-shadow: 2px 4px 4px rgba(0, 0, 0, 0.2);
    font-weight: 500;
}
.qty_control {
    height: fit-content ;
    max-width: 160px;
}
.remove_from_cart {
    cursor: pointer;
    transition: color 150ms ease-out;
    padding: 0 15px;
}
.remove_from_cart:hover {
    color: $red_1;
}
.single_product_price,
.total_product_price {
    position: relative;
}

// cart item igredients controls
.cart_ingQty_edit,
.cart_ingQty_save {
    color: #333;
    cursor: pointer;
    transition: color 150ms ease-in-out;
    // opacity: 0.8;
}
.cart_ingQty_edit:hover,
.cart_ingQty_save:hover {
    color: $green_1;
}

// cart item ing qty
.cart_ingQty {
    color: $green_1;
    background-color: #fff;
    border-radius: 3px 3px 0px 0px;
    transition: all 150ms ease-out;
    transition-property: opacity, transform;
    border: 1px solid $green_1;
}
.cart_ingQty i {
    font-weight: 100 !important;
}
.cart_ingQty i::before {
    vertical-align: middle !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 22px;
    font-weight: 100 !important;
    @media (min-width: 768px) {
        font-size: 24px;
    }
}
.cart_ingQty_inc,
.cart_ingQty_dec,
.cart_ingQty_value {
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    position: relative;
    user-select: none;
}
.cart_ingQty_inc,
.cart_ingQty_dec {
    font-size: 20px;
    cursor: pointer;
    position: relative;
}
.cart_ingQty_value {
    border-left: 1px solid $green_1;
    border-right: 1px solid $green_1;
    cursor: auto;
}