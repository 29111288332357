.choose_delivery {
    // height: 80px;
    box-shadow: $box_shadow_1;
    position: relative;
    // position: fixed;
    // bottom: 0;
    background-color: #fff;
    align-items: center;
    justify-content: stretch;
    width: 100%;
    flex-grow: 1 !important;
    padding: 0;
    // z-index: 3;
    @media (max-width: 575px) {
        position: fixed;
        bottom: 50px;
        box-shadow: $box_shadow_2;
        // height: 100px;
    }
    // @media (min-width: 768px) {
        // top: 60px;
    // }
}
.choose_delivery_hidden {
    visibility: hidden;
    pointer-events: none;
}
.delivery-content {
    box-sizing: border-box;
    max-width: 100%;
    margin: 0;
}
.pokupi,
.dostava {
    height: 60px;
    // border-radius: 4px;
    color: #fff;
    transition: background-color 150ms ease-out;
    @media (min-width: 576px) {
        border-radius: 4px;
    }
}
.pokupi {
    background: $blue_1;
}
.pokupi:hover {
    background: $blue_1_hover;
}
.dostava {
    background: $red_1;
}
.dostava:hover {
    background: $red_1_hover;
}

.pokupi-icon,
.dostava-icon {
    border-radius: 4px;
    // padding: 20px;
    height: 60px;
    // background: #00000027;
    @media (min-width: 576px) {
        height: 100%;
        background: #00000027;
    }
}

.dostava-text,
.pokupi-text {
    font-style: normal;
    font-weight: 400;
    // letter-spacing: 1px;
    color: #FFFFFF;
    font-size: 16px;
    white-space: nowrap;
    @media (min-width: 576px) {
        font-size: 18px;
    }
    @media (min-width: 768px) {
        font-size: 19px;
    }
    @media (min-width: 992px) {
        font-size: 20px;
        letter-spacing: 0.03em;
    }
    @media (min-width: 1200px) {
        font-size: 22px;
    }
    @media (min-width: 1400px) {
        font-size: 24px;
    }
}