.cart_layout {
    background-color: #fff;
    min-height: 100vh;
    min-width: 100vw;
    
    @media screen and (min-width: 768px) {
        min-height: 100%;
        min-width: unset;
    }
}
.cart_list_header {
    border-right: 1px solid $red_1;
    background-color: #333;
}
.cart_list_header>div {
    white-space: nowrap;
    border: 1px solid $red_1;
    border-right: none;
    padding: 0.5rem;
    color: $red_1;
    color: #fff;
    font-weight: 500;
}

.order_button {
    background: $red_1;
    border: 1px solid #BF181E;
    box-sizing: border-box;
    border-radius: 3px;
    
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 23px;

    text-align: center;
    letter-spacing: 0em;
    text-transform: uppercase;

    color: #FFFFFF;
    width: 100%;

    @media (min-width: 576px) {
        font-size: 17px;
        letter-spacing: 0.1em;
        width: 300px;
    }
}
.order_icon::before {
    transform: rotate(90deg) translateX(-3px);
    font-weight: bold;
}
// .order_icon {
//     transform: translateY(-25px);
// }

.empty_cart_text {
    font-size: 1.3rem;
    font-weight: 600;
    color: rgb(133, 133, 133);
}