.pizza_sizes {
    position: relative;
    background: #FAFAFA;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    @media (min-width: 1200px) {
        padding-top: 20px;
    }
}
.sizes,
.types {
    transform: scale(0.8);
    @media (min-width: 1200px) {
        transform: scale(1);
    }
}
// .sizes {
//     @media (min-width: 1400px) {
//         padding-left: 9rem !important;
//     }
// }
.size_heading,
.type_heading {
    margin: 0 auto;
}
.size_item,
.type_item {
    height: auto;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;
}
.size_item {
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
}
.size_title {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #333333;
    background-color: $yellow_1;
    padding:0.2rem 1.2rem;
    white-space: nowrap;
    width: 100%;
}
.size_image {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    background-color: #222222;
    height: 100%;
    width: 100%;
}
.size_description {
    border: 1px solid $yellow_1;
    box-sizing: border-box;
    width: 100%;
}
.size_key {
    background-color: $yellow_1;
    height: 100%;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
}
.size_value {
    white-space: nowrap;font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #333333;
}

.types {
    display: flex;
    flex-flow: column;
    justify-content: space-around;
}
.type_image {
    width: 80px;
    border: 4px solid #333333;
    box-sizing: border-box;
    border-radius: 100px;
    overflow: hidden;
    margin-bottom: 0.5rem;
    // @media (min-width: 1400px) {
    //     width: 100px;
    // }
}
.type_image>img {
    transform: scale(1.1);
}
.type_description {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #333333;
    background-color: $yellow_1;
    padding:0.2rem;
    border-radius: 4px;
    white-space: nowrap;
    min-width: 100%;
}