
$box_shadow_0: 0px 2px 6px rgba(0, 0, 0, 0.08);
$box_shadow_1: 0px 2px 6px rgba(0, 0, 0, 0.15);
$box_shadow_2: 0px 0px 14px rgba(0, 0, 0, 0.35);
$box_shadow_3: 0px 3px 3px rgba(0, 0, 0, 0.25);

$filter_drop_shadow : drop-shadow(2px 2px 6px rgba(0, 0, 0, 0.2));

$red_1: #ED1C24;
$red_1_hover: #ff0004;

$green_1:  #4BA345;
$green_1_hover:  #5ecf56;

$yellow_1: #EDD229;
$yellow_1_hover: #ffeb66;

$blue_1: #006991;
$blue_1_hover: #0e7eaa;

$grey_1: #F2F2F2;
$grey_2: #F4F4F4;