// flag
.jamaica *{
    z-index: 2;
}
.jamaica::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    // bottom: 35%;
    height: 65%;
    max-height: 133px;
    width: 30vw;
    background-image: url('../../img/brush-1.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left top;
    z-index: 1;
}
.jamaica::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    // top: 35%;
    height: 65%;
    max-height: 133px;
    width: 30vw;
    background-image: url('../../img/brush-2.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right bottom;
    z-index: 1;
}

// headings
.heading::before {
    content: '';
    position: absolute;
    top: calc(100% + 10px);
    left: 50%;
    transform: translateX(-50%);
    width: 145px;
    max-width: 100%;
    height: 3px;
    background: linear-gradient(90deg, $green_1 75%, transparent 75%);
}
.heading::after {
    content: '';
    position: absolute;
    top: calc(100% + 10px);
    left: 50%;
    transform: translateX(-50%);
    width: 145px;
    max-width: 100%;
    height: 3px;
    background: linear-gradient(90deg, transparent 78%, $red_1 78%);
}

.sub_heading {
    position: relative;
    width: fit-content;
    text-shadow: 0px 0px 2px #fff;
}
// sub_headings
.sub_heading::before {
    content: '';
    position: absolute;
    top: 110%;
    left: 0;
    width: 145px;
    max-width: 100%;
    height: 3px;
    background: linear-gradient(90deg, $green_1 75%, transparent 75%);
}
.sub_heading::after {
    content: '';
    position: absolute;
    top: 110%;
    left:0;
    width: 145px;
    max-width: 100%;
    height: 3px;
    background: linear-gradient(90deg, transparent 78%, $red_1 78%);
}

/* underline element on hover */
.hover-underline {
    position: relative;
    width: fit-content;
}
.hover-underline::after {
    position: absolute;
    content: '';
    top: 100%;
    left: 0;
    width: 100%;
    height: 2px;
    background-color:$red_1;
    /* transition: width 150ms ease-out; */
    transition: transform 300ms ease-out;
    transform: scaleX(0);
    transform-origin: right;
    z-index: 4;
}
.hover-underline:hover::after {
    /* width: 100%; */
    transform: scaleX(1);
    transform-origin: left;
}

.single_product_price::before {
    content: 'Jed. cena';
    position: absolute;
    bottom: 110%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 13px;
    
    @media screen and (min-width: 768px) {
        display: none;
    }
}
.total_product_price::before {
    content: 'Ukupna cena';
    position: absolute;
    bottom: 110%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 13px;
    
    @media screen and (min-width: 768px) {
        display: none;
    }
}