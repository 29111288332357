#loader {
    position: fixed;
    background: #fff;
    z-index: 99999999999;

    top: 0;
    left: 0;
    height: 100%;
    width: 100vw;
}
.spinner {
    width: 40px;
    height: 40px;
    top: 50%;
    left: 50%;
    margin-left: -40px;
    margin-top: -40px;
    -webkit-animation: rotatee 2s infinite linear;
    animation: rotatee 2s infinite linear;
}
.dot1, .dot2, .spinner {
    position: absolute;
}
.dot1, .dot2 {
    background-color: #cf0000;
}
.dot1, .dot2 {
    width: 60%;
    height: 60%;
    display: inline-block;
    top: 0;
    border-radius: 100%;
    -webkit-animation: bouncee 2s infinite ease-in-out;
    animation: bouncee 2s infinite ease-in-out;
}
.dot2 {
    top: auto;
    bottom: 0;
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
}
@-webkit-keyframes bouncee {
    0%,100%{
        -webkit-transform:scale(0)
    }
    50%{
        -webkit-transform:scale(1)
    }
}

@keyframes bouncee {
    0%,100%{
        transform:scale(0);
        -webkit-transform:scale(0)
    }
    50%{
        transform:scale(1);
        -webkit-transform:scale(1)
    }
}
@-webkit-keyframes rotatee{
    100%{
        -webkit-transform:rotate(360deg)
    }
}
@keyframes rotatee{
    100%{
        transform:rotate(360deg);
        -webkit-transform:rotate(360deg)
    }
}