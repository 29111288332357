.product_with_ings {
    background: #FFFFFF;
    border-radius: 3px;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
    @media (min-width: 768px) {
        box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.2);
    }
}
.product_with_ings_name {
    font-weight: bold;
    font-size: 1rem;
    // line-height: 20px;
    text-align: center;
    letter-spacing: 0;
    text-transform: uppercase;
    color: #333333;
    @media (min-width: 576px) {
        font-size: 1.5rem;
    }
    @media (min-width: 768px) {
        font-size: 2.5vw;
        font-weight: 600;
    }
    @media (min-width: 1400px) {
        letter-spacing: 0.05em;
        font-size: 2vw;
        font-weight: 500;
    }
}
.default_ingredient {
    border: 1px solid $red_1;
    border-radius: 3px;
    font-size: 14px;
    color: $red_1;
    @media (min-width: 576px) {
        font-size: 16px;
    }
}
.product_with_ings_select {
    background: #F4F4F4;
    border: 1px solid #AEAEAE;
    box-sizing: border-box;
    border-radius: 3px;
    letter-spacing: 0;

    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: #000000;

    @media (min-width: 576px) {
        font-size: 14px;
        letter-spacing: 0.05em;
    }
    @media (min-width: 768px) {
        font-size: 15px;
        letter-spacing: 0.07em;
    }
}

.qty {
    background-color: $grey_1;
    color: #000;
    // opacity: 0;
    border-radius: 3px;
    height: 100%;
    border: 1px solid #BDBDBD;
}
.qty i {
    font-weight: 100 !important;
}
.qty i::before {
    vertical-align: middle !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 22px;
    font-weight: 100 !important;
    @media (min-width: 768px) {
        font-size: 24px;
    }
}
.qty_inc,
.qty_dec,
.qty_value {
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    position: relative;
    user-select: none;
    font-weight: 600;
}
.qty_inc,
.qty_dec {
    font-size: 20px;
    cursor: pointer;
    position: relative;
    color: #222222;
}
.qty_value {
    border-left: 1px solid #BDBDBD;
    border-right: 1px solid #BDBDBD;
}

// .add_to_cart {
//     background: $red_1;
//     border: 1px solid #BF181E;
//     box-sizing: border-box;
//     border-radius: 3px;
    
//     font-style: normal;
//     font-weight: 300;
//     font-size: 16px;
//     line-height: 23px;

//     text-align: center;
//     letter-spacing: 0em;
//     text-transform: uppercase;

//     color: #FFFFFF;

//     @media (min-width: 768px) {
//         font-size: 17px;
//         letter-spacing: 0.1em;
//     }
// }