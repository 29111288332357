body.home {
    padding-top: 50px;
    padding-bottom: 110px;

    @media (min-width: 576px) {
        padding-top: 134px;
        padding-bottom: 50px;
    }
    
    @media (min-width: 768px) {
        padding-top: 144px;
        padding-bottom: unset;
    }
}
body.home .menu_bar_container {
    display: none;
}
body.home .choose_delivery {
    display: block !important;
}