
/* notification */
.custom_notification {
    background-color: #333;
    color:#fff;
    border: 1px solid rgba(146, 146, 146, 0.322);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
    position: fixed;
    bottom: 16px;
    right: 16px;
    z-index: 1006;
    transition: all 500ms ease;
    transition-property: opacity, transform;
    margin-bottom: 10px;
    opacity: 0;
    transform: translateY(150%);
    pointer-events: none;
    max-width: 80%;
    min-width: 200px;
    cursor: pointer;
    box-sizing: border-box;
    box-shadow: $box_shadow_2;
}
.show_notification {
    opacity: 1;
    transform: translateY(0);
    pointer-events: all;
}
.custom_notification i {
    margin-right: 5px;
}
@media (max-width:600px) {
	.custom_notification {
        width: 100%;
        max-width: 100%;
        right: 0;
        border-radius: 0;
    }
}