.menu_nav_container {
    background: #FAFAFA;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    // position: fixed;
    // top: 50px;
    // z-index: 3;
    // @media (min-width: 768px) {
    //     top: 60px;
    // }
}
.menu_bar_container {
    @media (max-width: 768px) {
        padding: 0;
    }
}
.menu_nav {
    display: flex;
    align-items: center;
    // justify-self: flex-end;
    margin-bottom: 0;
}
.menu_nav_toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height */
    
    letter-spacing: 0.1em;
    text-transform: uppercase;
    background-color: $red_1;
    color: #fff;
    border-radius: 0;
}
.menu_nav_toggle::after {
    content: '';
    width: 10px;
    height: 10px;
    border: 2px solid #fff;
    position: absolute;
    top: 12px;
    right: 40px;
    transform:rotate(45deg);
    // transform: ;
    border-color: transparent #fff #fff transparent;
}
.menu_nav_item {
    @media (max-width: 768px) {
        width: 100%;
        box-sizing: border-box;
    }
}
.menu_nav_link {
    display: flex;
    align-items: center;
    height: 40px;
    font-size: 0.8rem;
    font-weight: bold;
    line-height: 20px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    width: 100%;
    color: #333333;
    justify-content: center;
    // @media (max-width: 768px) {
    //     width: 100%;
    //     box-sizing: border-box;
    // }
    @media (min-width: 992px) {
        height: 50px;
        font-size: 1rem;
    }
}
.menu_active {
    background:unset;
    color:$red_1;
    font-weight: 800 !important;
    @media (min-width: 768px) {
        background:$yellow_1;
        color: #333333;
    }
}