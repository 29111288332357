.location_layout {
    background-color: #fff;
    min-height: 100vh;
    min-width: 100vw;
    @media screen and (min-width: 768px) {
        min-height: 300px;
        min-width: unset;
    }
}
.locations_header {
    box-shadow: $box_shadow_1;
    width: 100%;
    padding: 0.5rem 1rem;
    font-weight: bold;
    color: $red_1;
    font-size: 20px;
}
.locations_header_icon {
    width: 80px;
}
.locations_caption {
    font-size: 20px;
    padding: 0.5rem 1rem;
}
.city_picker {
    padding: 0.5rem 1rem;
    font-size: 20px;
    font-weight: 600;
    background-color: $red_1;
    color: #fff;
    text-transform: uppercase;
    border-radius: 4px;
    transition: background-color 150ms ease-in-out;
    // max-width: 300px;
}
.city_picker:hover {
    background-color: $red_1_hover;
}
.red_glow {
    filter:drop-shadow(0px 0px 3px $red_1);
}
.blue_glow {
    filter:drop-shadow(0px 0px 3px $blue_1);
}
// pickup blue theme
.pickup .locations_header {
    color: $blue_1;
}
.pickup .city_picker {
    background-color: $blue_1;
}
.pickup .city_picker:hover {
    background-color: $blue_1_hover;
}
// pickup map & list
#map {
    display: none;
    width: 50vh;
    height: 50vh;
    background-color: #f4f4f4;
    @media screen and (min-width: 768px) {
        display: block;
    }

}
.restaurant_list_container {
    // height: 500px;
    width: 100%;
    overflow: auto;
	scroll-behavior: smooth;
    @media screen and (min-width: 768px) {
        height: 50vh;
        width: 250px;
    }
}
.restaurant_card {
    border-bottom: 1px solid #ececec;
    cursor: pointer;
    transition: box-shadow 150ms ease-in-out;
    // transition-property: background-color;
}
.restaurant_card.active {
    // background-color: rgb(241, 246, 255);
    box-shadow: inset 0px 0px 20px rgba(0, 0, 0, 0.3);
}
.restaurant_button,
.marker_button {
    width: 100%;
    max-width: 400px;
    background-color: $blue_1;
    color: #fff;
    padding: 0.3rem;
    text-transform: uppercase;
    font-weight: 400;
    border-radius: 4px;
    transition: background-color 150ms ease-in-out;
}
.marker_button {
    color: $red_1;
    background-color: #fff;
    font-size: 16px;
    border: 1px solid #fff;
    transition: all 150ms ease-in-out;
    transition-property: color, background-color;
}
.marker_button:hover {
    background-color: $red_1;
    color: #fff;
}
.restaurant_button:hover {
    background-color: $blue_1_hover;
}
.restaurant_icon::before {
    transform: translateY(-3px) !important;
}
.restaurant_map_link {
    border: 1px solid transparent;
    transition: border-color 150ms ease-in-out;
    border-radius: 4px;
}
.restaurant_map_link:hover {
    border-color: $blue_1;
}
.restaurant_map_link i::before {
    transform: translateY(-3px);
}

.back_button {
    background-color: #fff;
    color: $red_1;
    border: 1px solid transparent;
    transition: all 150ms ease-in-out;
    transition-property: box-shadow;
    border-radius: 4px;
    // box-shadow: $box_shadow_1;
}
.back_button:hover {
    box-shadow: $box_shadow_3;
}
// pickup blue theme
.pickup .back_button {
    color: $blue_1;
}

// popup marker card
.marker_popup_card {
    font-family: 'Dosis', sans-serif;
}
.marker_popup_title {
    font-weight: 700;
    font-size: 18px;
    color: $red_1;
}
.marker_popup_image {
    width: 100% !important;
    height: 110px;
    object-fit: cover;
}
.marker_popup_content {
	background-color: $red_1;
	color: #fff;
}
.marker_popup_phone {
    font-weight: 500;
    font-size: 16px;
}
// google maps infowindow overwrite max height
.gm-style-iw.gm-style-iw-c,
.gm-style-iw-d {
    max-height: unset !important;
}
// ///////////////////////////////////////


// blue scrollbar for location list/* scrollbar */
/* width */
.restaurant_list_container::-webkit-scrollbar {
	width: 8px;
}

  /* Track */
.restaurant_list_container::-webkit-scrollbar-track {
	// background-color: $yellow_1;
	background-color: #fff;
}

/* Handle */
.restaurant_list_container::-webkit-scrollbar-thumb {
	background:$blue_1; 
}

/* Handle on hover */
.restaurant_list_container::-webkit-scrollbar-thumb:hover {
	background: $blue_1_hover;
}