html, body {
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    -o-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
	// font-smoothing: antialiased;
}
body {
	background: #ffffff none repeat scroll 0 0;
    color: #333;
    font-family: 'Dosis', sans-serif;
	font-size: 16px;
}
h1,h2,h3,h4,h5,h6 {
	color: #333;
	font-family: 'Dosis';
}
p {
	color:#333;
	line-height: 28px;
	margin-bottom: 25px;
}
a {
	color: #333;
	text-decoration: none;
	-webkit-transition: all 0.15s ease-in-out;
    transition: all .15s ease-in-out;
    text-decoration: none;
    user-select: none;
}
a:hover {
	text-decoration: none;
	color: unset;
}
a:focus {
	outline: none;
}
img {
	max-width: 100%;
}
button {
	-webkit-transition: all 0.15s ease-in-out;
	transition: all .15s ease-in-out;
	border: none;
	outline: none !important;
}
.btn:focus,
.navbar-toggler:focus {
	box-shadow: none !important;
	outline: none !important;
}
ul,li,ol {
	margin: 0;
    padding: 0;
    list-style: none;
}
fieldset {
	border: 0 none;
	margin: 0 auto;
	padding: 0;
}
label {
	color: #6c757d;
}
input:focus,
textarea:focus {
	outline: none;
	box-shadow: none;
}
iframe {
	border: 0;
	display: block;
}
video {
	width: 100%;
}

select:focus{
	outline: none;
	box-shadow: none;
}
div {
	box-sizing: border-box !important;
}
.heading {
	transform: scale(1.15);
    font-weight: 500;
	width: fit-content;
	letter-spacing: 0.1em;
}
.fit_content {
	width: fit-content;
}

/* scrollbar */
/* width */
::-webkit-scrollbar {
	width: 8px;
}

  /* Track */
::-webkit-scrollbar-track {
	background-color: $yellow_1;
	background-color: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background:$red_1; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: $green_1;
}