.ing {
    box-shadow: $box_shadow_1;
    // filter: $filter_drop_shadow;
    position: relative;
    cursor: pointer;
}
.ingQty {
    position: absolute;
    top: 0;
    left: 0;
    background-color: $red_1;
    color: #fff;
    border-radius: 3px 3px 0px 0px;
    transition: all 150ms ease-out;
    transition-property: opacity, transform;
    opacity: 0;
    pointer-events: none;
}
.show_ing_qty {
    opacity: 1;
    // transform: scale(1);
    pointer-events: all;
}
.toggle_ing {
    padding-top: 30px;
    user-select: none;
}
.toggle_ing>img {
    user-select: none;
}
.ingQty i {
    font-weight: 100 !important;
}
.ingQty i::before {
    vertical-align: middle !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 22px;
    font-weight: 100 !important;
    @media (min-width: 768px) {
        font-size: 24px;
    }
}
.ingQty_inc,
.ingQty_dec,
.ingQty_value {
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    position: relative;
    user-select: none;
}
.ingQty_inc,
.ingQty_dec {
    font-size: 20px;
    cursor: pointer;
    position: relative;
}
.ingQty_value {
    border-left: 1px solid #FD5057;
    border-right: 1px solid #FD5057;
    cursor: auto;
}
.ingName,
.ingPrice {
    user-select: none;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: #333333;
    @media (min-width: 768px) {
        font-size: 16px;
        line-height: 19px;
    }
}